import { initHotReload } from './lib/hot-reload.js';
import cookie from 'cookie';

import { OuterData } from '../common/pagedata.js';
import { PageHandler, pageHandlers } from './pages/pages.js';
import { Context } from '../common/context.js';

import { defaultLanguage, getTranslation, LanguageId, str } from '../common/i18n.js';
import { reportEventsFromServer, setUpTrackers } from './lib/events.js';
import { setContentUrlPrefix } from './lib/images.js';
import { expect, maybe } from '../common/util.js';
import { SiteNavigation } from './components/site-nav.js';
import { SiteData } from './templates/site.js';
import { recoverLegacyAuth } from './lib/auth.js';
import { initSentry } from './sentry.js';

if (process.env.NODE_ENV==='development') {
	// Must use require here as import statements are only allowed
	// to exist at top-level.
	// require("preact/debug");
	require('preact/debug');
}

const data = JSON.parse(document.getElementById('data')!.textContent!) as OuterData;
if (!data.disableTracking) initSentry('https://fde0b9af65a04a2bb83b61d7e906b275@o306148.ingest.sentry.io/1764020');

const cookies = cookie.parse(document.cookie);
cookies.csrf = data.csrf!;

const lang = (cookies.lang || defaultLanguage) as LanguageId;

const context: Context = {
	domain: data.domain!,
	user: data.user,
	cookies,
	url: location.pathname + location.search,
	lang,
	t: getTranslation,
	str,
	resourceHashes: data.resourceHashes,
};
context.t = getTranslation.bind(context);
context.str = str.bind(context);

(window as any).siteContext = context;

const initPage = () => {
	
	const siteData = maybe(document.querySelector<HTMLScriptElement>('script#site-data'), el => JSON.parse(expect(el.textContent)) as SiteData);
	const siteNav = document.querySelector<HTMLElement>('.site-nav');
	if (siteNav && siteData) SiteNavigation.init(siteNav, context, { ...data, ...siteData });
	
	if (data.pageHandler) {
		(pageHandlers as Record<string, PageHandler>)[data.pageHandler](context, data.pageData);
	}
	
	if (siteData?.legacyAuthDomain) recoverLegacyAuth(siteData?.legacyAuthDomain);
	
};

if (data.contentUrlPrefix) setContentUrlPrefix(data.contentUrlPrefix);
initPage();

if (process.env.NODE_ENV === 'development') {
	const devConfig = data.devConfig;
	if (devConfig?.devSocketPort || devConfig?.devSocketPath) initHotReload(devConfig);
}

const { serverEvents } = data;
setTimeout(() => setUpTrackers(data), 1);
if (serverEvents?.length) setTimeout(() => reportEventsFromServer(serverEvents));

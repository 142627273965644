import * as Sentry from '@sentry/browser';

export { Sentry };
(window as any).Sentry = Sentry;

export function initSentry(dsn: string) {
	Sentry.init({
		dsn,
	});
}

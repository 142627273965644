import { Context } from '../../common/context.js';
import { ComponentFactory, h, render } from 'preact';
import { RestoreForm, SetPasswordForm, SignInForm, SignUpForm } from '../components/auth.js';
import { BlogIndexWrapper, BlogPostWrapper } from '../components/blog.js';

import { SiteContext } from '../context.js';


export type PageHandler = (c: Context, data: any) => void;

const component = (cls: ComponentFactory<any>, selector: string) => (c: Context, data: any) => {
	const it = document.querySelector(selector)!;
	render(h(SiteContext.Provider, { value: { context: c }, children: h(cls, { context: c, ...data }) }), it.parentElement!, it);
};

type ComponentExportKey<M extends object> = { [K in keyof M]: M[K] extends ComponentFactory<any> ? K : never }[keyof M];

const lazyComponent = <M extends object, K extends ComponentExportKey<M>> (loader: () => Promise<M>, key: K, selector: string) => async (c: Context, data: any) => {
	const module = await loader();
	const cls = module[key] as ComponentFactory<any>;
	const it = document.querySelector(selector)!;
	render(h(cls, { context: c, ...data }), it.parentElement!, it);
};

export const pageHandlerMap = {
	signIn: lazyComponent(() => import('../components/auth.js'), 'SignInForm', '.auth'),
	signUp: lazyComponent(() => import('../components/auth.js'), 'SignUpForm', '.auth'),
	restore: lazyComponent(() => import('../components/auth.js'), 'RestoreForm', '.auth'),
	setPassword: lazyComponent(() => import('../components/auth.js'), 'SetPasswordForm', '.auth'),
	builder: async (c: Context, data: any) => {
		const { initBuilderPage } = await import('../components/builder/builder.js');
		initBuilderPage(c, data);
	},
	order: lazyComponent(() => import('../components/order-standalone.js'), 'StandaloneOrderPage', '.purchases'),
	products: lazyComponent(() => import('../components/products-page.js'), 'ProductsPage', '.products-page'),
	settings: lazyComponent(() => import('../components/dashboard/settings.js'), 'SettingsEditor', '.settings'),
	faq: lazyComponent(() => import('../components/faq-page.js'), 'FaqPage', '.faq'),
	form: lazyComponent(() => import('../components/form-page.js'), 'FormPage', '.form-page'),
	commissions: lazyComponent(() => import('../components/commissions.js'), 'CommissionsPage', '.commissions'),
	store: lazyComponent(() => import('../templates/pages/store.js'), 'StorePage', '.store'),
	storeProductPage: lazyComponent(() => import('../components/store-product.js'), 'StoreProductPage', '.store-product'),
	giftcards: lazyComponent(() => import('../components/giftcards.js'), 'GiftcardOrderPage', '.giftcard-order'),
	designPackage: lazyComponent(() => import('../components/design-package.js'), 'DesignPackagePage', '.design-package'),
	singleProduct: lazyComponent(() => import('../components/single-product.js'), 'SingleProductPage', '.single-product'),
	orderItemPreview: lazyComponent(() => import('../components/order-item-preview.js'), 'OrderItemPreviewPage', '.order-item-preview'),
	digitizeOrderItem: lazyComponent(() => import('../components/digitize-order-item.js'), 'DigitizeOrderItemPage', '.digitize-order-item'),
	dashboard: lazyComponent(() => import('../components/dashboard.js'), 'DashboardPage', '.dashboard-page'),
	shoppingCart: lazyComponent(() => import('../components/cart/cart.js'), 'ShoppingCart', '.cart'),
	page: lazyComponent(() => import('../components/page.js'), 'PageWrapper', '.page'),
	blogIndex: lazyComponent(() => import('../components/blog.js'), 'BlogIndexWrapper', '.blog'),
	blogPost: lazyComponent(() => import('../components/blog.js'), 'BlogPostWrapper', '.blog'),
	previewRenderer: async () => {
		const { initLoopbackPreviewRenderer } = await import('../components/preview-renderer.js');
		initLoopbackPreviewRenderer();
	},
	unsubscribe: lazyComponent(() => import('../components/unsubscribe.js'), 'Unsubscribe', '.unsubscribe-page'),
	jerseymaker: lazyComponent(() => import('../components/jersey-maker-landing.js'), 'JerseyMakerLandingPage', '.jersey-maker-landing'),
	joinLeague: lazyComponent(() => import('../components/join-league.js'), 'JoinLeaguePage', '.join-league'),
};

export type PageHandlerId = keyof typeof pageHandlerMap;

export const pageHandlers: {
	[key in PageHandlerId]: PageHandler
} = pageHandlerMap;

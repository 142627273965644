/* eslint-disable no-useless-escape */
/* eslint-disable no-misleading-character-class */
import { ClientDevConfig } from '../../common/pagedata.js';
import { debounce, keepExisting } from '../../common/util.js';

let attempts = 30;

interface DevSocketMessage {
	hashes?: Record<string, string>;
	restarting?: true;
	ready?: true;
}
	
export const initHotReload = (config: ClientDevConfig): void => {
	
	let connecting = false;
	console.log(`hot reload on`, config);
	
	const matchName = (path: string) => {
		const fm = path.match(/^(\/client\/)([^!/]+?)(!(\w+))?([^!/]+?)$/);
		if (!fm) return;
		const [_, dir, pre, _bang, hash, post] = fm;
		const file = pre + post;
		return { dir, pre, post, file, hash };
	};
	const findStyles = () => keepExisting([...document.querySelectorAll<HTMLLinkElement>('link[rel=stylesheet]')].map(link => {
		const fm = matchName(new URL(link.href).pathname);
		return fm ? { ...fm, link } : null;
	}));
	const findScripts = () => keepExisting([...document.querySelectorAll<HTMLScriptElement>('script[src]')].map(script => {
		const fm = matchName(new URL(script.src).pathname);
		return fm ? { ...fm, script } : null;
	}));
	
	let restarting = false;
	let reloading = false;
	let updating = false;
	const setTitle = () => {
		const base = document.title.replace(/^\s*[⁉🟎🗘⏻]*\s*/u, '');
		const status = (
			(connecting ? '⁉' : '')
			+ (restarting ? '⏻' : '')
			+ (reloading ? '🗘' : '')
			+ (updating ? '🟎' : '')
		);
		document.title = status.length ? status + ' ' + base : base;
	};
	let oldLinks: HTMLLinkElement[] = [];
	const finishUpdate = () => {
		for (const link of oldLinks) link.remove();
		oldLinks = [];
		updating = false;
		setTitle();
	};
	const scheduleFinishUpdate = debounce(finishUpdate, 1000);
	
	const reload = () => {
		if (!reloading || restarting) return;
		location.reload();
	};
	const scheduleReload = debounce(reload, 1000);
	
	const checkHashes = (hashes: Record<string, string>) => {
		
		if (restarting) return;
		
		for (const s of findStyles()) {
			const hash = hashes[s.file]?.slice(0, 6);
			if (!hash || hash == s.hash) continue;
			if (oldLinks.includes(s.link)) continue;
			updating = true;
			setTitle();
			const url = new URL(s.link.href);
			url.pathname = s.dir + s.pre + '!' + hash + s.post;
			oldLinks.push(s.link);
			const add = s.link.cloneNode(true) as HTMLLinkElement;
			add.onload = finishUpdate;
			add.href = url.href;
			s.link.parentNode!.insertBefore(add, s.link);
			scheduleFinishUpdate();
		}
		
		for (const s of findScripts()) {
			const hash = hashes[s.file]?.slice(0, 6);
			if (!hash || hash == s.hash) continue;
			console.log(s.file, ':', s.hash, '->', hash, (hashes[s.file]));
			reloading = true;
			setTitle();
			scheduleReload();
		}
		
	};
	
	const connect = () => {
	
		const url = new URL(location.origin);
		url.protocol = url.protocol.replace(/^http/, 'ws');
		if (config.devSocketPath) url.pathname = String(config.devSocketPath);
		else url.port = String(config.devSocketPort);
		console.log('hot reload: connecting to', url.href);
		connecting = true;
		setTitle();
		const ws = new WebSocket(url.href);
			
		ws.onopen = function (): void {
			console.log('hot reload: ready');
			connecting = false;
			setTitle();
		};
			
		ws.onmessage = function (ev): void {
			
			const msg: DevSocketMessage = JSON.parse(ev.data);
			console.log('hot reload:', msg);
			
			if (msg.hashes) checkHashes(msg.hashes);
			
			if (msg.restarting) {
				restarting = true;
				setTitle();
			}
			if (msg.ready) {
				restarting = false;
				setTitle();
				if (reloading) reload();
			}
			
		};
			
		ws.onclose = function (e): void {
			if (attempts) {
				console.log(`hot reload: lost connection... retrying every 500ms for ${attempts} attempts.`);
				setTimeout(connect, 1000);
				--attempts;
			}
			else attempts = 30;
		};
			
		ws.onerror = function (err): void {
			console.error(err);
			ws.close();
		};
		
	};
	
	connect();
	
};
	
